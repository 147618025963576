.Toastify__toast {
    border-radius: 14px;
}

.Toastify__toast-body {
    font-size: 12px;
}

.Toastify__progress-bar {
    height: 2px;
}