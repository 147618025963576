p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

.invoice-container {
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 600px;
}

.invoice-header {
  background-color: #ffff;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 2rem 0 0 0;
}

.invoice-header__seller-section,
.invoice-header__buyer-section {
  min-width: 36%;
  max-width: 48%;
}

.invoice-header__seller-section .title,
.invoice-header__buyer-section .title {
  font-size: 0.8571428571428571rem;
  font-weight: bold;
  line-height: 20px;
  text-transform: uppercase;
}

.invoice-header__seller-section .subtitle,
.invoice-header__buyer-section .subtitle {
  font-size: 0.8571428571428571rem;
  line-height: 20px;
  width: 125px;
  min-width: 125px;
}

.invoice-header__seller-section .info-container,
.invoice-header__buyer-section .info-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.invoice-header__seller-section .info-container .info,
.invoice-header__buyer-section .info-container .info {
  font-size: 0.8571428571428571rem;
  line-height: 20px;
}

.invoice-header__seller-section .info-container .info::before,
.invoice-header__buyer-section .info-container .info::before {
  content: ' :  ';
  font-weight: normal;
}

.invoice-table {
  margin: 2rem 0 0 0;
}

.invoice-table__header {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  padding: 18px 16px;
  display: flex;
}

.invoice-table__body {
  border-bottom: thin solid #e5e7e9;
}

.order-item-container {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e5e7e9;
  padding: 18px 16px;
}

.invoice-table__footer {
  display: block;
  margin: 0 0 0 auto;
  padding: 18px 16px;
  width: 47%;
}

.invoice-table__footer .info-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.invoice-table__footer .info-container .subtitle {
  font-size: 0.8571428571428571rem;
  line-height: 20px;
  font-weight: bold;
}

.invoice-table__footer .info-container .info {
  font-size: 0.8571428571428571rem;
  line-height: 20px;
}

.invoice-table__header .product-container,
.invoice-table__header .quantity-container,
.invoice-table__header .price-container,
.invoice-table__header .total-container {
  text-transform: uppercase;
  font-weight: bold;
  color: #31353b;
  font-size: 0.8571428571428571rem;
}

.order-item-container .product-container {
  width: 52%;
  text-align: left;
}

.order-item-container .quantity-container {
  width: 8%;
  text-align: right;
}

.order-item-container .price-container {
  width: 20%;
  text-align: right;
}

.order-item-container .total-container {
  width: 20%;
  text-align: right;
}

.text-medium {
  color: #31353b;
  font-size: 0.8571428571428571rem;
}

.text-small {
  color: #31353b;
  font-size: 0.6571428571428571rem;
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}
