
::-moz-selection {
  color: white;
  background: #FCB017;
}

::selection {
  color: white;
  background: #FCB017;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fefefe;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f0f0f0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}

#header {
  z-index: 9;
  position: fixed;
}

#header .pro-sidebar {
  height: 100vh;
  margin-top: -55px;
}

#header .closemenu {
  color: rgb(167, 167, 167);
  position: absolute;
  right: -8px;
  z-index: 9999;
  line-height: 15px;
  border-radius: 50%;
  font-size: 20px;
  top: -40px;
  cursor: pointer;
  background: rgb(152 152 152 / 16%);
  width: 20px;
  height: 20px;
}

#header .closemenu:hover {
  font-weight: 600;
  color: rgb(19, 19, 19);
}

#header .pro-sidebar {
  width: 100%;
  min-width: 220px;
}

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 18px;
  padding: 0 20px;
  color: #000;
  font-weight: 600;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 5px 0px;
  font-weight: 600;
  font-size: 14px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: transparent;
  color: #000;
  border-radius: 3px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-inner-list-item .pro-icon-wrapper .pro-item-content {
  color: #000;
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  background-color: transparent;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-color: rgba(220, 220, 220, 0.9) !important;
}

.pro-sidebar.collapsed .pro-menu ul .pro-menu-item.pro-sub-menu .pro-inner-list-item .popper-inner {
  background-color: #fff !important;
  border: 2px solid #f5f8f7;
  padding-left: 0px !important;
}

.pro-menu-item li:hover {
  background-color: #ecf0ef;
}

#header .logo {
  padding: 20px;
}

.pro-sidebar .pro-menu ul .pro-sub-menu .pro-inner-list-item {
  background-color: transparent;
}

@media only screen and (max-width: 720px) {
  /* html {
      overflow: hidden;
    } */
}

.pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content {
  overflow-y: auto;
}